<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="lg"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseTicketModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <b-card v-if="!isView">
      <validation-observer
          ref="observerRef"
          #default="{handleSubmit}">
        <b-form
            @keydown.enter.stop.prevent=""
            @submit.prevent="handleSubmit(submitValidatedTicketLocal)">
          <form-ticket :ticket="ticketLocal"/>
          <button ref="submitTicketFormRef"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card v-else>
      <view-ticket :ticket="ticket"/>
    </b-card>

  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import FormTicket from '../form/Ticket'
import ViewTicket from '../views/Ticket'

export default {
  components: {
    ValidationObserver,
    FormTicket,
    ViewTicket
  },
  props: {
    ticket: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const ticketLocal = ref(props.ticket)
    const submitTicketFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(ticketLocal, (val) => {
      emit('update:ticket', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseTicketModal = (modalTicket) => {
      if (modalTicket.trigger == 'backdrop') {
        // Click outside
        modalTicket.preventDefault()
        submitTicketFormRef.value.click()
      }
    }

    const submitValidatedTicketLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedEventLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      ticketLocal,
      submitTicketFormRef,
      observerRef,

      // Computed
      hideFooter,

      // Methods
      tryCloseTicketModal,
      submitValidatedTicketLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    ticket: function (val) {
      this.ticketLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>